import _, { min } from "lodash";
import VaccineDetails from "./VaccineDetails";

export default class Vaccine {
    
    readonly details : VaccineDetails;
    
    readonly vaccines: Array<string> = [
        "COVID-19", // 0
        "BCG", // 1
        "<em>Haemophilus influenzae</em> tipo b (Hib)", // 2
        "Hepatitis B (HepB)", // 3
        "DPT (Difteria tosferina Tétanos)", // 4. Editada
        "Toxoide tetánico y diftérico del adulto (TD)", // 5
        "Tétanos Difteria-Tosferina Acelular (TdaP)", // 6
        "SRP (Sarampión- Rubéola- paperas)", // 7
        "Varicela (VAR)", // 8
        "Zoster recombinante (RZV)", // 9
        "Virus del papiloma humano (VPH)", // 10
        "Neumococo (Conjugada-PCV10,PCV13,PCV15,PCV20)", // 11 Editada
        "Neumococo (Polisacarida-PPSV23)", // 12 Nueva
        "Hepatitis A (HepA)", // 13
        "Meningococo A,C,W, Y (MenACWY)", // 14
        "Meningococo B (MenB)", // 15
        "Polio", // 16
        "Rotavirus", // 17
        "Influenza estacional", // 18
        "Influenza trivalente", // 19 Nueva
        "Fiebre amarilla" // 20
    ];

    readonly vaccinekeys: Array<string> = [
        "covid",
        "bcg",
        "hib",
        "hepb",
        "dpt",
        "td",
        "tdap",
        "srp",
        "var",
        "rzv",
        "vph",
        "pcv",
        "pcv", // nueva
        "hepa",
        "menacwy",
        "menb",
        "polio",
        "rotavirus",
        "influenza",
        "influenza", // nueva
        "fiebreamarilla"
    ];

    constructor(VaccineDetails: VaccineDetails){
        this.details = VaccineDetails; 
    }

    show(key: number){

        return this.vaccines[key];

    }

    getStringKey(key: number) : string{
        return this.vaccinekeys[key];
    }

    showDetails(key: number){
        return this.details.getDetails(this.vaccinekeys[key]);
    }

    calculatePlan(vaccinePlans: Array<Array<Number>>): Array<number> | any{
        let ageVaccines = vaccinePlans.shift();
        
        let finalVaccines: Array<Number> = [];

        ageVaccines?.map((vaccineInstance, index) => {
            let minimum_value : Number = vaccineInstance;

            if(vaccineInstance !== 10){
                vaccinePlans.map((vaccinePlanInstance: any) => {
                    if(vaccinePlanInstance[index] < minimum_value){
                        minimum_value = vaccinePlanInstance[index];
                    }
                });
            }
            finalVaccines.push(minimum_value);
        });
        
        return finalVaccines;

        //return _.zip(...vaccinePlans).map(list => _.min(list));

    }
}