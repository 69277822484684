import './App.css';
import "./fonts/FoundrySterling-Light.ttf";
import Navbar from './app/components/navbar';
import {
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Main from './app/views/main';
import Videos from './app/views/videos';
import Library from './app/views/library';
import Algo from './app/views/algorithm/algorithm';
import Titlebar from './app/components/titlebar';
import { useState, useEffect } from 'react';
import Menu from './app/components/menu'; 
import { useServiceWorker } from './useServiceWorker';
import Newversion from './app/components/newversion';

function App() {
  
  const [title, setTitle] = useState('a');

  const location = useLocation();
  const [showMenu, setShowMenu] = useState(false);
  const [newVersion, setNewVersion] = useState(false);

  const { waitingWorker, showReload, reloadPage } = useServiceWorker();

  useEffect(() => {
      const sections: any = {
        '/': '',
        '/algorithm': 'Algoritmo de vacunación',
        '/video': 'Videoteca',
        '/library': 'Biblioteca'
      }
      setTitle(sections[location.pathname]);

      if (showReload && waitingWorker) {
        setNewVersion(true);
      }

  }, [location, waitingWorker, showReload, reloadPage]);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  }

  const changeNewVersion = (status: boolean) => {
    setNewVersion(status);
  }

  let isInstalled = localStorage.getItem('pwaInstalled') === '1' || false;

  if (window.matchMedia('(display-mode: standalone)').matches) {
      // User is currently navigating on the PWA so yes it's installed
      localStorage.setItem('pwaInstalled', '1');
      isInstalled = true;
  } else {
      //User is navigating in browser
      window.addEventListener('beforeinstallprompt', () => {
          localStorage.setItem('pwaInstalled', '0');
          isInstalled = false;
          localStorage.removeItem('authorized');
          //User can get an installation prompt meaning the app is not installed
      });
      window.addEventListener('onappinstalled', () => {

          localStorage.setItem('pwaInstalled', '1');
          isInstalled = true;
          // Enviar estadística de instalación
          
          fetch(process.env.REACT_APP_API_URL+'new-install', {
            headers: {
              'Content-Type': 'Application/json',
              'Token': 'Bearer CfXpCr4EgS0gmDQbO252ML4NkysrJTiJsh1AuDe0a3834909',
              'Access-Control-Allow-Origin': 'https://app.vax-connect.com'
            }
          }).then(() => alert('installed'));
      });
  }

  return (
    <div>
      
      <Newversion newVersion={newVersion} hideNewversion={changeNewVersion}></Newversion>

      <Titlebar title={title} showMenu={toggleMenu}></Titlebar>
      
      <Navbar title={title}></Navbar>

      <Menu display={showMenu} showMenu={toggleMenu}></Menu>

      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/video" element={<Videos />} />
        <Route path="/library" element={<Library />} />
        <Route path="/algorithm" element={<Algo />} />
      </Routes>
    </div>
  );
}

export default App;
