import { Parser } from "html-to-react";
import definitions from "../data/definitions";
import { useState } from "react";

export default function VaccinationIndexModal(props: { changeIndexModal: any; }){
        
  const changeIndexModal = props.changeIndexModal;

  const [viewDefinitions, updateDefinitions] = useState(false);
    
    return (
        <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 sm:ml-4 sm:mt-0 sm:text-left">
                    <div className="mb-5 flex">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24" strokeWidth={1.5} stroke="green" className="w-10 h-10">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z" />
                      </svg>
                      <span className="ml-5">
                        <p>Cobertura PAI.</p> 
                        <p>Pulse en la vacuna para maś detalles.</p>
                      </span>
                    </div>
                    <h3 className="text-base font-semibold leading-6 text-gray-900" id="modal-title">Código de colores</h3>
                    <div className="mt-2">
                        <div className="bg-green-600 text-white p-2 mb-2">Vacunación recomendada para individuos que:
                            <ul className="list-disc ml-6">
                                <li>Cumplan los requisitos de edad</li>
                                <li>Carezcan de documentación de vacunación</li>
                                <li>No presenten pruebas de infección en el pasado</li>
                            </ul>
                        </div>
                        <div className="bg-green-300 p-2 mb-2">Vacunación recomendada para individuos con un factor de riesgo adicional u otra indicación</div>
                        <div className="bg-blue-300 p-2 mb-2">Vacunación recomendada deacuerdo a criterio médico</div>
                        <div className="bg-orange-300 p-2 mb-2">Precaución: evaluar beneficio - riesgo.</div>
                        <div className="bg-red-600 text-white p-2">No recomendado / Contraindicado</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                    onClick={() => changeIndexModal(false)}  
                    type="button" 
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cerrar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}