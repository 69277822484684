import { useServiceWorker } from "../../useServiceWorker";

export default function Newversion(props: {newVersion: boolean, hideNewversion: any})
{
    const { waitingWorker, showReload, reloadPage } = useServiceWorker();
    
    if(props.newVersion){
        return (
            <div className="bg-black bg-opacity-75 fixed h-full left-0 top-0 w-full z-50">
                <div id="info-popup" tabIndex={-1} className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full">
                    <div className="relative p-4 w-full h-full md:h-auto">
                        <div className="relative p-4 bg-white rounded-lg shadow dark:bg-white md:p-8">
                            <div className="mb-4 text-sm font-light text-gray-500 dark:text-gray-400">
                                <h3 className="mb-3 text-2xl font-bold text-gray-900">Nueva versión</h3>
                                <p>
                                    Hay una nueva versión de esta aplicación. <br />
                                    Vuelva a abrir la aplicación para ver la nueva versión
                                </p>
                            </div>
                            <div className="flex justify-around items-center pt-0 space-y-4 sm:flex sm:space-y-0">
                                <button className="px-5 py-1 mb-2 business-blue text-white rounded" onClick={() => props.hideNewversion(false)}>Aceptar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (<div className="hidden"></div>)
    }
}