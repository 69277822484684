let paiAges = [
    {
        min: 0,
        max: 2,
        organism: [0,1,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    },
    {
        min: 2,
        max: 4,
        organism: [0,0,1,1,1,0,0,0,0,0,0,1,0,0,0,0,1,1,0,0,0],
    },
    {
        min: 4,
        max: 6,
        organism: [0,0,1,1,1,0,0,0,0,0,0,1,0,0,0,0,1,1,0,0,0],
    },
    {
        min: 6,
        max: 7,
        organism: [1,0,1,1,1,0,0,0,0,0,0,1,0,0,0,0,1,0,1,0,0],
    },
    {
        min: 7,
        max: 9,
        organism: [1,0,0,0,1,0,0,0,0,0,0,1,0,0,0,0,1,0,1,0,0],
    },
    /* van 5*/
    {
        min: 9,
        max: 12,
        organism: [1,0,0,0,1,0,0,0,0,0,0,1,0,0,0,0,1,0,0,0,0],
    },
    {
        min: 12,
        max: 18,
        organism: [1,0,0,0,1,0,0,1,1,0,0,1,0,1,0,0,1,0,0,0,1],
    },
    {
        min: 18,
        max: 23,
        organism: [1,0,0,0,1,0,0,1,1,0,0,0,0,1,0,0,1,0,0,0,1],
    },
    {
        min: 23,
        max: 60,
        organism: [1,0,0,0,1,0,0,1,1,0,0,0,0,1,0,0,1,0,0,0,1],
    },
    {
        min: 60,
        max: 108,
        organism: [1,0,0,0,1,0,0,1,1,0,0,0,0,0,0,0,1,0,0,0,1],
    },
    /* van 10*/
    {
        min: 108,
        max: 120,
        organism: [1,0,0,0,1,0,0,0,0,0,1,0,0,0,0,0,0,0,0,0,1],
    },
    {
        min: 120,
        max: 144,
        organism: [1,0,0,0,1,1,1,0,0,0,1,0,0,0,0,0,0,0,0,0,1],
    },
    {
        min: 144,
        max: 216,
        organism: [1,0,0,0,1,1,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0],
    },
    {
        min: 216,
        max: 228,
        organism: [1,0,0,0,1,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    },
    {
        min: 228,
        max: 324,
        organism: [1,0,0,0,1,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    },
    /* van 15*/
    {
        min: 324,
        max: 600,
        organism: [1,0,0,0,1,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    },

    {
        min: 600,
        max: 672,
        organism: [1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,0],
    },
    {
        min: 672,
        max: 720,
        organism: [1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,0],
    },
    {
        min: 720,
        max: 780,
        organism: [1,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,0,0,0,1,0],
    },
    {
        min: 780,
        max: 10000,
        organism: [1,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,0,0,1,1,0]
    },
];

export default paiAges;