import _ from 'lodash';
import { Paciente } from './types/Paciente';
import Vaccine from './Vaccine';
import VaccineDetails from './VaccineDetails';

export default class ConditionVaccination{

    paciente: Paciente;
    
    constructor(paciente: Paciente){
        this.paciente = paciente;
    }

    readonly conditions = [
        "Embarazo",
        "Malformación congénita del tracto gastrointestinal",
        //"Mujer", en GenderVaccination
        "Inmunocomprometidos (Excluyendo VIH)",
        "Infección VIH - CD4<15% o <200mm\u00B3",
        "Infección VIH - CD4≥15% y ≥200mm\u00B3",
        "Asplenia, deficiencias del complemento",
        "Enfermedad renal terminal o en hemodiálisis",
        "Enfermedad cardíaca o enfermedad pulmonar",
        "Enfermedad hepática crónica",
        "Diabetes",
        "Zonas endémicas",
        "Personal de salud",
        "Hombres que tienen relaciones sexuales con hombres",
        "Alergia al huevo (Reacción severa)",
        //"Reacción alérgica a algun componente de la vacuna",
        //"Enfermedad aguda moderada o grave, con o sin fiebre"
    ]

    readonly vaccinePlans = [
        [6,-1,10,6,10,10,6,-1,-1,10,-1,10,4,4,2,10,10,6,6,2],// "Embarazo"
        [10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,-1,-1,10,10,10],// "Malformación congénita del tracto gastrointestinal"
        //[6,10,10,6,10,6,10,10,10,10,6,10,10,10,10,10,10,10,10,10],// "Mujer" -> en GenderVaccination
        [6,-1,6,6,10,10,10,-1,-1,6,6,6,4,4,4,2,2,2,10,-1], // "Inmunocomprometidos (Excluyndo VIH)"
        [6,-1,4,6,10,10,10,-1,-1,6,6,6,6,6,4,2,10,2,10,-1], // "Infección VIH - Conteo CD4<15% or <200mm^3"
        [6,-1,4,6,10,10,10,6,3,6,6,6,6,6,4,2,10,2,10,-1], // "Infección VIH - Conteo CD4≥15% and ≥200mm^3"
        [6,10,6,6,10,10,10,6,6,6,6,6,4,6,6,10,10,2,10,10], // "Asplenia, deficiencias del complemento"
        [6,10,4,6,10,10,10,6,6,6,6,6,4,4,4,10,10,2,10,10], // "Enfermedad renal terminal o en hemodiálisis"
        [6,10,4,6,10,10,10,6,6,6,6,6,4,4,4,10,10,2,4,10], // "Enfermedad cardíaca o enfermedad pulmonar;alcoholismo"
        [6,10,4,6,10,10,10,6,6,6,6,6,6,4,4,10,10,2,10,10], // "Enfermedad hepática crónica"
        [6,10,4,6,10,10,10,6,6,6,6,6,4,4,4,10,10,2,10,10], // "Diabetes"
        [6,10,10,6,10,10,10,10,10,10,10,10,6,6,4,10,10,10,10,6],// "Zonas endémicas"
        [6,3,4,6,10,6,10,6,6,6,6,4,4,4,4,10,10,6,10,10], // "Personal de salud"
        [6,10,4,6,10,10,10,6,6,6,6,4,6,4,4,10,10,10,10,10], // "Hombres que tienen relaciones sexuales con hombres"
        [10,10,10,10,10,10,10,2,10,10,10,10,10,10,10,10,10,2,10,2], // "Alergia al huevo (Reacción severa)"
        //[-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1], // "Reacción alérgica a algun componente de la vacuna"
        //[3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3],// "Enfermedad aguda moderada o grave, con o sin fiebre"
    ]

    /**
     * Returns a reduction of all condition arrays to a single array
     * with the most prevalent vaccine
     * 
     * Uses lodash (_)
     * _.zip -> transpose selectedConditions 2D array 
     * _.min -> gets minimum value of each transposed list
     * 
     */
    getPlan(conditions: Array<number>): Array<number> {

        let selectedConditions: Array<Array<number>> = [];

        conditions.forEach((condition: number) => {
            selectedConditions.push(this.vaccinePlans[condition]);
        })

        let finalList : any =  _.zip(...selectedConditions).map(list => _.min(list));

        if(finalList){
            return finalList;
        } else {
            return [];
        }

    }

    handle(vaccines: Array<Array<number>>) : Array<number>{
        let vaccinePlan : Array<number> = this.getPlan(this.paciente.conditions);
        
        if(vaccinePlan){
            vaccines.push(vaccinePlan);
        }
        
        return new Vaccine(new VaccineDetails()).calculatePlan(vaccines);
    }
}