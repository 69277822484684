import { Key, useEffect, useState } from "react";
import Media from "../components/media";
import { Offline, Online } from "react-detect-offline";

export default function Library() {
  
  type Content = {id:0,description: '', type:null, value: String}
  
  type Chapter = {id: Key, title: String, content: Array<Content>}

  const [resources, setResources] = useState(Array<Chapter>);

  useEffect(()=> {
    fetch(process.env.REACT_APP_API_URL+'recursos', {
      headers: {
        'Content-Type': 'Application/json',
        'Token': 'Bearer CfXpCr4EgS0gmDQbO252ML4NkysrJTiJsh1AuDe0a3834909',
        'Access-Control-Allow-Origin': 'https://app.vax-connect.com'
      }
    })
      .then(response => response.json())
      .then(data => setResources(data))
      .then(()=> console.log(resources))
      .catch(error => console.log(error));

      // Obtener el token de permiso
    fetch("https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/namespaces/fn-0b6f128c-d445-4bbb-bc75-08a4adfd3a15/actions/validateToken?blocking=true&result=true", {
      method: "POST",
      body: JSON.stringify({token:"AEIOU"}),
      headers: {
        'Content-Type': 'Application/json',
        'Authorization': 'Basic MjcxMDZmNzMtMzNkMy00ZDk0LWJlMTQtYzJiNzc5Njk3MDdkOjBuRHZMTWVCVkpQZ2ZUOGVRTFRaUUhNcGQ2SmFaYzF2ZzNPQWpVR3YzV1RyWnZQN3Rva2czMFRySXZFenZOTUw='
      }
    })
  }, [process.env.API_URL+'recursos'])
    

    const onButtonClick = (e : any) => {
      let archivo = e.target.value;
      // using Java Script method to get PDF file
      try{
        fetch(archivo, {
          method: 'GET',
          mode: 'cors'
        })
        .then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = archivo;
                alink.click();
            })
        })
      } catch (error){
        console.log(error);
      }
    }
  
    return (
        <div className='flex-col px-3 md:flex-row mb-10 pb-10 app-body-margin-top only-pwa'>
          <Offline>
            <div  className="rounded overflow-hidden shadow-md bg-white mt-4 md:mx-2">
              <div className="px-6 py-4">
                Conéctese a internet vara acceder a los recursos digitales.
              </div>
            </div>
          </Offline>
          <Online>
            {
              resources.map( chapter => (
                <div key={chapter.id } className="flex flex-col content-start rounded overflow-hidden mt-2 md:mx-2 bg-white">
                  <span className="p-2 bg-business-dark-blue-text text-white font-medium">
                    {chapter.title}
                  </span>

                <div className="pl-4 my-2">
                
                  {chapter.content.map(file => <Media key={file.id} medium={file} />)}
                  
                </div>
                
                </div>
                )
              )
              
            }
            </Online>
        </div>
    )
}