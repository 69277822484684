import vaccineAges from "../data/vaccineAges";
import { AgeVaccine } from "./AgeVaccine"
import ConditionVaccination from "./ConditionVaccination";
import GenderVaccination from "./GenderVaccination";
import { Paciente } from "./types/Paciente";
export default class AgeVaccination{

    paciente: Paciente

    constructor(paciente: Paciente){
        this.paciente = paciente;
    }

    monthToYears(months: number): number{
        return Math.ceil(months/12);
    }

    yearsToMonths(months: number): number{
        return months * 12;
    }

    vaccinesByAge(months: number) : any
    {
        let found: any;

        this.ages.map(function(current){
            if(((current.min <= months) && (months < current.max))){
                found = current;
            }
        })

        return found.vaccines;
    }

    handle(vaccines: Array<Array<number>>): Array<number> {
        if(vaccines){
            let age =  (this.paciente.ageUnits == 'anios') ? this.yearsToMonths(Number(this.paciente.age)) : Number(this.paciente.age)
            vaccines.push(this.vaccinesByAge(age));
        }
        return new GenderVaccination(this.paciente).handle(vaccines);

    }

    readonly ages: Array<AgeVaccine> = vaccineAges;

}