let definitions = `
    <h2 className="font-semibold py-2">Indicación</h2>

    <p>Se refiere a las recomendaciones de inmunización en pacientes para cada vacuna específica (Grupos de
    edad, Condiciones especiales...)</p>

    <h2 className="font-semibold py-2">Contraindicación</h2>

    <p>Las contraindicaciones (condiciones en un receptor que aumentan el riesgo de una reacción
    adversa grave) para la vacunación son condiciones bajo las cuales no se deben administrar
    vacunas. Debido a que la mayoría de las contraindicaciones son temporales, las vacunas a menudo
    se pueden administrar más tarde cuando la condición que conduce a una contraindicación ya no
    existe. No se debe administrar una vacuna cuando existe una contraindicación.(Centros para el
    Control y Prevención de Enfermedades, 2023)</p>

    <h2 className="font-semibold py-2">Precaución</h2>

    <p>Una precaución es una condición en un receptor que podría aumentar el riesgo de una reacción
    adversa grave, podría causar confusión diagnóstica o podría comprometer la capacidad de la
    vacuna para producir inmunidad.Una persona podría experimentar una reacción más grave a la
    vacuna de lo que se hubiera esperado de otra manera; Sin embargo, el riesgo de que esto suceda
    es menor que el riesgo esperado con una contraindicación. En general, las vacunas deben diferirse
    cuando hay una precaución. Sin embargo, una vacuna podría estar indicada en presencia de una
    precaución si el beneficio de la protección de la vacuna supera el riesgo de una reacción adversa.
    (Centros para el Control y Prevención de Enfermedades, 2023)</p>

    <h2 className="font-semibold py-2">Observación</h2>

    <p>Son las diferentes alternativas de inmunización que aplican para ciertos tipos de vacunas en los casos
    que haya enfermedades o condiciones especiales (Diferencia en el número de dosis o en los intervalos
    de aplicación)</p>
`;

export default definitions;