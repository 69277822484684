
export default function Media(props: {medium: any}) {
    const prefix = 'https://ucts.nyc3.digitaloceanspaces.com/';

    const downloadIcon = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-2">
        <path strokeLinecap="round" strokeLinejoin="round" d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15M9 12l3 3m0 0l3-3m-3 3V2.25" />
    </svg>;
  
  const onButtonClick = (e : any) => {
    let archivo = e.target.value;
    // using Java Script method to get PDF file
    try{
      fetch(archivo, {
        method: 'GET'
      })
      .then(response => {
          response.blob().then(blob => {
              // Creating new object of PDF file
              const fileURL = window.URL.createObjectURL(blob);
              // Setting various property values
              let alink = document.createElement('a');
              alink.href = fileURL;
              alink.download = archivo;
              alink.click();
          })
      })
    } catch (error){
        alert("error");
      console.log(error);
    }
  }
  
    if(props.medium.extension === 'SOUND'){
        return (
            <>
                <audio controls>
                    <source src={props.medium.enlace} type="audio/mpeg" />
                    Your browser does not support the audio tag.
                </audio>
            </>
        )
    }
    if(props.medium.extension === '.pdf'){
        return (
            <>
                <a className="flex algin-middle p-2" key={props.medium.id} href={props.medium.enlace} download target="_blank"> {downloadIcon} {props.medium.titulo}</a>
            </>
        )
    }
    if(props.medium.extension === '.link'){
        return(
            <>
            <a href={props.medium.value} rel="noreferrer" target="_blank">{props.medium.description}</a>
            </>
        )
    }

    if(props.medium.extension === '.jpg'){
        return(
            <>
            <img src={props.medium.enlace} alt="" />
            </>
        )
    }
    if(props.medium.extension === '.mp4'){
        return(
            <>
            <video width="320" height="240" controls>
                <source src={props.medium.enlace}  type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            </>
        )      
    }

    return (
        <span className="fixed w-full top-0-important flex justify-between bg-business-dark-blue-text shadow-md"></span>
    )
  }