"use client"

import React, { useState } from "react";
import Vaccine from "./model/Vaccine";
import ConditionVaccination from "./model/ConditionVaccination";
import Algorithm from "./model/Algorithm";
import _ from "lodash";
import VaccinationIndexModal from "./components/VaccinationIndexModal";
import VaccinationDetailsModal from "./components/VaccinationDetailsModal";
import VaccineDetails from "./model/VaccineDetails";
import VaccineInstitution from "./model/VaccineInstitution";
import { Parser } from "html-to-react";
import DefinitionsModal from "./components/DefinitionsModal";
import VaccinationFeedbackModal from "./components/VaccinationFeedbackModal";

export default function Algo() {

  const [paciente, updatePaciente] = useState({
    'gender': 'hombre',
    'age': 18,
    'ageUnits': 'anios',
    'conditions': new Array(14).fill(100)
  });

  const conditionVaccination = new ConditionVaccination(paciente);

  const vaccine = new Vaccine(new VaccineDetails());
  const algorithm = new Algorithm();
  const institutions = new VaccineInstitution();

  const [sexo, updateSex] = useState('hombre');
  const [edad, updateAge] = useState('18');
  const [unidad, updateUnits] = useState('anios');
  const [vaccinePlan, updateVaccinePlan] = useState(new Array(19).fill(10));
  const [mostrarResultado, updateMostrarResultado] = useState(false);
  const [showIndexModal, updateShowIndexModal] = useState(false);
  const [showFeedbackModal, updateFeedbackModal] = useState(false);
  const [showDefinitionsModal, updateShowDefinitionsModal] = useState(false);
  const [showVaccinationDetailsModal, updateShowVaccinationDetailsModal] = useState(false);
  const [currentVaccineDetails, updateCurrentVaccineDetails] = useState(``);
  
  const onOptionChange = (e : any) => {
    updateSex(e.target.value)
    updatePaciente({...paciente, gender: e.target.value, conditions: new Array(14).fill(100)});
  }  

  const onAgeChange = (e : any) => {
    updateAge(e.target.value)
    updatePaciente({...paciente, age: e.target.value, conditions: new Array(14).fill(100)});
  }  

  const onUnitsChange = (e : any) => {
    updateUnits(e.target.value)
    updatePaciente({...paciente, ageUnits: e.target.value, conditions: new Array(14).fill(100)});
  }  

  const restart = (e : any) => {
    updateSex('hombre');
    updateUnits('anios');
    updateAge('18');
    updatePaciente({...paciente, conditions: new Array(14).fill(100)});
    updateMostrarResultado(false);
  }

  const patientConditionsUpdate = (key: number) => {
    let actualConditions = paciente.conditions;

    if(_.indexOf(actualConditions, key) === -1){
      actualConditions[key] = key;
    } else {
      
      actualConditions[key] = 100;
    }
    
    updatePaciente({...paciente, conditions: actualConditions});
  }

  const toggleIndexModal = (state: boolean) => {
    updateShowIndexModal(state);
  }
  const toggleFeedbackModal = (state: boolean) => {
    updateFeedbackModal(state);
  }

  const toggleVaccinationDetailsModal = (state: boolean) => {
    updateShowVaccinationDetailsModal(state);
  }

  const toggleDefinitionsModal = (state: boolean) => {
    updateShowDefinitionsModal(state);
  }

  const calculate = () => {
    let vaccinePlan: Array<number> = algorithm.calculate(paciente);
    updateVaccinePlan(vaccinePlan);
    updateMostrarResultado(true);
  }

  const printAge = () => {
    if(paciente.ageUnits === 'anios'){
      return `${paciente.age} años`;
    } else {
      return `${paciente.age} meses`;
    }
  }

  const getRelevance = (value: number): string => {
    let colorstring = '';

    switch(value){
      case 6:
        colorstring = 'bg-green-600 text-white';
        break;
      case 5:
        colorstring = 'bg-purple-300 text-black';
        break;
      case 4:
        colorstring = 'bg-green-300';
        break;
      case 3:
        colorstring = 'bg-blue-300';
        break;
      case 2:
        colorstring = 'bg-orange-300';
        break;
      default:
        colorstring = 'bg-red-600 text-white';
        break;
    }

    return colorstring;
  }

  const getRelevanceName = (value: number): string => {
    let names : any = {
      "-1": "No Recomendado",
      "2": "Precaución: Evaluar riesgo/beneficio",
      "3": "Bajo criterio médico",
      "4": "Recomendado para individuos con factor de riesgo",
      "5": "Actualización vacunas saltadas",
      "6": "Recomendado",
    }

    return names[value];
  }
  
  const printVaccineDetails = (key : number) =>
  {
    let htmlDetails : string = vaccine.details.show(vaccine.getStringKey(key));
    updateCurrentVaccineDetails(htmlDetails);
    toggleVaccinationDetailsModal(true);
  }

  const sortVaccinePlan = () => {
    let list = new Array();
    
    vaccine.vaccines.forEach((item: string, key: number) => {
      list.push({key: vaccinePlan[key], name: item, vaccineid: key})
    })
    
    return _.sortBy(list, (o: any) => o.key);
  }

  const showOrganism = (vaccineid: number) => {
    let organism = institutions.vaccinesByOrganism(paciente);
    
    if(organism[vaccineid] > 0)
      return <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24" strokeWidth={1.5} stroke="green" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z" />
            </svg>
    else
      return <p></p>
  }

  const listVaccinePlan = () => {
    let currentPlan = _.first(sortVaccinePlan()).key;
    
    let list = sortVaccinePlan().map((item, key) => {
      let printHeader = false;

      if(item.key !== currentPlan || key === 0){
        printHeader = true;
        currentPlan = item.key;
      }

      if(item.key !== 10){

        if(printHeader){

          return <span key={key}>
            <h2 className="my-2 bg-gray-300 border-2 font-semibold p-1">{getRelevanceName(currentPlan)}</h2>
            <div  className={getRelevance(item.key)+' my-1 p-1 flex justify-between'} onClick={() => printVaccineDetails(item.vaccineid)}>
              <p className={getRelevance(item.key)}>{Parser().parse(item.name)}</p>
              <div>{showOrganism(item.vaccineid)}</div>
            </div>
          </span>

        } else {
          
          return <div 
            key={key} 
            className={getRelevance(item.key)+' my-1 p-1 flex justify-between'}
            onClick={() => printVaccineDetails(item.vaccineid)}>
            <p className={getRelevance(item.key)}>{Parser().parse(item.name)}</p>
              <div>{showOrganism(item.vaccineid)}</div>
          </div>
        }

      }

    });
    return <div>{list}</div>
  } 

  const listConditions = () => {
    let conditions = conditionVaccination.conditions;
    
    let list = conditions.map((item: string, key: number) => {
    
      let esHombre = paciente.gender === 'hombre';
      let edadPaciente = paciente.ageUnits=='anios'? paciente.age*12 : paciente.age;
      let menorDeEdad = edadPaciente <= 132;

        if(item === 'Embarazo' && ( esHombre || menorDeEdad ) ){
          return false;
        } else {
          let classNames = "";
          if(_.indexOf(paciente.conditions, key) !== -1){
            classNames = "bg-gray-300 flex py-2 shadow-md rounded m-1 mx-0 px-2";
          } else {
            classNames = "bg-white flex py-2 shadow-md rounded m-1 mx-0 px-2";
          }
          return <div key={key} onClick={() => patientConditionsUpdate(key)} 
          className={classNames} >
            <label >
            <span className="ml-1">{item}</span>
            </label>
          </div>
        }
    })
    return <div>{list}</div>
  }

  const selectedConditionsList = () => {
    
    let list = paciente.conditions.map((item:number, key: number) => {
      if(item < 100){
        return <li key={key}>{conditionVaccination.conditions[item]}</li>
      }
    })

    return <ul className="list-disc list-outside ms-8">{list}</ul>

  }

  return (
    <div className='flex-col px-3 md:flex-row mb-10 pb-10 app-body-margin-top only-pwa'>
      <div className={mostrarResultado?"hidden":""}>
      <div className=" rounded overflow-hidden  shadow-md bg-gray-100 mt-4 md:mx-2">
        <div className="px-6 py-4">
          <div className="font-bold text-xl mb-2">Datos básicos</div>
            <div className="flex-column mt-4">
              <h2 className="block">Sexo:</h2>
              <div className="flex justify-around">
                <button 
                  onClick={onOptionChange} 
                  value='hombre' 
                  className={(sexo==='hombre'?'bg-gray-400 text-white shadow-inner':'bg-gray-200')+" p-3 px-5 shadow-md transition-all"}>
                    Hombre
                  </button>
                <button 
                  onClick={onOptionChange} 
                  value='mujer' 
                  className={(sexo==='mujer'?'bg-gray-400 text-white shadow-inner':'bg-gray-200 shadow-md')+" p-3 px-5 shadow-md transition-all"}>
                    Mujer
                  </button>
              </div>
            </div>
            <div className="flex-column mt-4">
              <label htmlFor="edad" className="block">Edad:</label>
              <input 
                type="number" 
                name="edad" 
                id="edad" 
                onChange={onAgeChange}
                value={edad}
                className="border-2 mr-2 w-full p-2"/>
            </div>
            <div className="flex-column mt-4">
              <div className="flex justify-around">

              <button 
                  onClick={onUnitsChange} 
                  value='anios' 
                  className={(unidad==='anios'?'bg-gray-400 text-white shadow-inner':'bg-gray-200 shadow-md')+" p-3 px-5 shadow-md transition-all"}>
                    Años
                  </button>
                <button 
                  onClick={onUnitsChange} 
                  value='meses' 
                  className={(unidad==='meses'?'bg-gray-400 text-white shadow-inner':'bg-gray-200 shadow-md')+" p-3 px-5 shadow-md transition-all"}>
                    Meses
                  </button>
                
              </div>
            </div>
            
            <div className="font-bold text-xl mb-2 mt-4">Otras Condiciones</div>
              <div className="flex-column max-h-80 overflow-auto">
                {listConditions()}
              </div>

          </div>
            <div className="flex flex-column mt-4 justify-center">
                  <button 
                    onClick={calculate} 
                    value="1"
                    className="px-5 py-3 mb-5 business-blue text-white rounded"
                  >
                    Obtener plan
                  </button>
            </div>
        </div>
      </div>

      <div className={mostrarResultado?"mb-8":"hidden"}>
        
        <div className="rounded-xl overflow-hidden mt-4 shadow-md bg-white p-2 md:mx-2">
          <span className="font-bold">
            {paciente.gender.toUpperCase()}, {printAge().toUpperCase()}.
          </span>
            {selectedConditionsList()}
        </div>

        <h2 className="block text-xl my-4 flex justify-between">
          <span>Plan de vacunación</span>
          <span>
            <button className="mr-3" onClick={() => toggleFeedbackModal(true)}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="rgb(220 38 38)" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 12.75c1.148 0 2.278.08 3.383.237 1.037.146 1.866.966 1.866 2.013 0 3.728-2.35 6.75-5.25 6.75S6.75 18.728 6.75 15c0-1.046.83-1.867 1.866-2.013A24.204 24.204 0 0 1 12 12.75Zm0 0c2.883 0 5.647.508 8.207 1.44a23.91 23.91 0 0 1-1.152 6.06M12 12.75c-2.883 0-5.647.508-8.208 1.44.125 2.104.52 4.136 1.153 6.06M12 12.75a2.25 2.25 0 0 0 2.248-2.354M12 12.75a2.25 2.25 0 0 1-2.248-2.354M12 8.25c.995 0 1.971-.08 2.922-.236.403-.066.74-.358.795-.762a3.778 3.778 0 0 0-.399-2.25M12 8.25c-.995 0-1.97-.08-2.922-.236-.402-.066-.74-.358-.795-.762a3.734 3.734 0 0 1 .4-2.253M12 8.25a2.25 2.25 0 0 0-2.248 2.146M12 8.25a2.25 2.25 0 0 1 2.248 2.146M8.683 5a6.032 6.032 0 0 1-1.155-1.002c.07-.63.27-1.222.574-1.747m.581 2.749A3.75 3.75 0 0 1 15.318 5m0 0c.427-.283.815-.62 1.155-.999a4.471 4.471 0 0 0-.575-1.752M4.921 6a24.048 24.048 0 0 0-.392 3.314c1.668.546 3.416.914 5.223 1.082M19.08 6c.205 1.08.337 2.187.392 3.314a23.882 23.882 0 0 1-5.223 1.082" />
            </svg>
            </button>
            <button className="mr-3" onClick={() => toggleIndexModal(true)}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
              </svg>
            </button>
            <button onClick={() => toggleDefinitionsModal(true)}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
              </svg>
            </button>
          </span>
        </h2>

        {listVaccinePlan()}

        <button 
          onClick={restart} 
          value="1"
          className="px-5 py-3 mb-5 mt-10 mx-auto business-blue text-white rounded flex"
        >
          <svg xmlns="http://www.w3.org/2000/svg" style={{position:"relative", bottom:"2px"}} viewBox="0 0 24 24" fill="white" className="w-6 h-6 display-inline">
            <path fillRule="evenodd" d="M20.25 12a.75.75 0 01-.75.75H6.31l5.47 5.47a.75.75 0 11-1.06 1.06l-6.75-6.75a.75.75 0 010-1.06l6.75-6.75a.75.75 0 111.06 1.06l-5.47 5.47H19.5a.75.75 0 01.75.75z" clipRule="evenodd" />
          </svg>
          Reiniciar consulta
        </button>
      </div>
      
      <div className={showIndexModal?'':'hidden'}>
      <VaccinationIndexModal changeIndexModal={() => toggleIndexModal(false)} />
      </div> 

      <div className={showFeedbackModal?'':'hidden'}>
      <VaccinationFeedbackModal changeFeedbackModal={() => toggleFeedbackModal(false)} />
      </div> 

      <div className={showDefinitionsModal?'':'hidden'}>
      <DefinitionsModal changeIndexModal={() => toggleDefinitionsModal(false)} />
      </div> 

      <div className={showVaccinationDetailsModal?'':'hidden'}>
      <VaccinationDetailsModal 
        currentVaccineDetails={currentVaccineDetails} 
        changeVaccinationDetailsModal={() => toggleVaccinationDetailsModal(false)} />
      </div> 
    </div>

  )
}