import UCTS_COLOR from '../../img/UCTS_COLOR.png'

export default function Menu(props: { display: boolean, showMenu: any}) {

    if(!props.display) return null;

    const reloadSite = () => {
      window.location.reload();
    }

    const appVersion = process.env.REACT_APP_VERSION;
    const appDate = process.env.REACT_APP_DATE;

    return (
        <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity">
      
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 sm:ml-4 sm:mt-0 sm:text-left">
                    <div className="mt-2 text-center">
                    <img src={UCTS_COLOR} alt="UCTS Logotipo" />
                    <p className="mt-5 flex flex-col">
                      <span>
                        Versión {appVersion} (Beta)
                      </span>
                      <span>
                        Datos del CDC actualizados a {appDate}
                      </span>
                    </p>
                    <ul className="my-5">
                      <li className="p-3"><strong>CREACIÓN Y ADMINISTRACIÓN</strong></li>
                      <li><strong>Luis Felipe Reyes</strong></li>
                      <li>M.D., Esp. Cuidado Crítico, Ph.D Ciencia Traslacional, M.Sc Salud Internacional</li>
                      <li><a href="mailto:luis.reyes5@unisabana.edu.co">luis.reyes5@unisabana.edu.co</a></li>

                      <li className="mt-3"><strong>Nury N. Olaya-Galán</strong></li>
                      <li>Microbióloga, M.Sc - Virología, Ph.D Ciencias Biomédicas</li>
                      <li><a href="mailto:nuryolga@unisabana.edu.co">nuryolga@unisabana.edu.co</a></li>

                      <li className="p-3"><strong>CRÉDITOS</strong></li>
                      <li><strong>Ana María Crispín Aldana</strong></li>
                      <li>Enfermera, Esp. Cuidado Crítico</li>
                      <li className="mt-3"><strong>Diego Alejandro Jaimes</strong></li>
                      <li>M.D., Esp. Med. Interna - Epidemiología</li>
                      <li className="mt-3"><strong>Edwin Silva</strong></li>
                      <li>M.D., Esp. Infectología</li>
                      <li className="mt-3"><strong>Cristian Serrano</strong></li>
                      <li>M.D, cPh.D</li>
                      <li className="mt-3"><strong>Gisell Bustos</strong></li>
                      <li>Bacterióloga, Esp. Microbiología clínica, cPh.D</li>

                      <li className="p-3"><strong>DESARROLLADORES</strong></li>
                      <li>Ricardo Otaño</li>
                      <li>Andrea Ramírez Tovar</li>

                      <li className="p-3"><strong>SOPORTE</strong></li>
                      <li>ucts@unisabana.edu.co</li>
                    </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                      onClick={() => props.showMenu()} 
                      type="button" 
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">
                        Cerrar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )

  }