import { ReactElement } from "react";
import * as vaccineDetails from "../data/vaccineDetails";

export default class VaccineDetails {
    
    readonly emptyDetail = {
        name: "",
        pai: "",
        appliance: [{
            target: "",
            numDose: "",
            doseInterval: "",
            reinforcement: "",
            totalDose: "",
        }],
        recommended: [],
        notRecommended: [],
        precautions: [],
        observations: [],
        coadministration: [],
    }

    getDetails(vaccine : string): JSON | Array<JSON>{
        return vaccineDetails.default[vaccine];
    } 

    show(vaccine: string): string {
        let htmlDetail = ``;
        let detail: any = this.getDetails(vaccine);
        if(Array.isArray(detail)){
            detail.map((subdetail) => {

                htmlDetail += `
                    <div class="mb-2">
                    <h2 class="text-white business-blue pl-2 py-1 font-bold">${subdetail.name}</h2>
                    <span class="flex justify-between my-1"><div class="font-bold">Cobertura PAI:</div><div>${subdetail.pai}</div></span>
                `;

                htmlDetail += `<p class="font-bold">Grupos de vacunación:</p>`;

                subdetail.appliance.map((item : any) => {
                    htmlDetail += `
                    <div class="font-bold bg-blue-300 p-2">${item.target}</div>
                    <div class="mb-2 border-2 px-2">
                        <div class="grid grid-rows-1 grid-flow-col gap-4 items-start">
                            <span><div class="font-bold"># dosis</div><div>${item.numDose}</div></span>
                            <span><div class="font-bold">Dosis totales</div><div>${item.totalDose}</div></span>
                            <span><div class="font-bold">Refuerzo</div><div>${item.reinforcement}</div></span>
                        </div>
                        <div class="font-bold">Intervalo</div><div>${item.doseInterval}</div>
                    </div>
                    `
                })
                    
                htmlDetail += `<p className="font-bold">Recomendaciones</p>`
                htmlDetail += this.printList(subdetail.recommended)
                
                htmlDetail += `<p className="font-bold">Contraindicaciones</p>`
                htmlDetail += this.printList(subdetail.notRecommended)

                htmlDetail += `<p className="font-bold">Precauciones</p>`
                htmlDetail += this.printList(subdetail.precautions)

                htmlDetail += `<p className="font-bold">Oservaciones</p>`
                htmlDetail += this.printList(subdetail.observations)

                htmlDetail += `<p className="font-bold">Coadministración</p>`
                htmlDetail += this.printList(subdetail.coadministration)

                htmlDetail += `</div>`
            });
        } else {

            
            htmlDetail = `
                    <h2 class="text-white business-blue pl-2 py-1 font-bold">${detail.name}</h2>
                    <div class="font-bold">Cobertura PAI:</div><div class="mb-2">${detail.pai}</div>
                `;
    
            htmlDetail += `<p class="font-bold">Grupos de vacunación:</p>`;

            detail.appliance.map((item : any) => {
                htmlDetail += `
                    <div class="font-bold bg-blue-300 p-2">${item.target}</div>
                    <div class="mb-2 border-2 px-2">
                        <div class="grid grid-rows-1 grid-flow-col gap-4 items-start">
                            <span><div class="font-bold"># dosis</div><div>${item.numDose}</div></span>
                            <span><div class="font-bold">Dosis totales</div><div>${item.totalDose}</div></span>
                            <span><div class="font-bold">Refuerzo</div><div>${item.reinforcement}</div></span>
                        </div>
                        <div class="font-bold">Intervalo</div><div>${item.doseInterval}</div>
                    </div>
                `
            })

            htmlDetail += `<p className="font-bold">Indicaciones / Recomendaciones</p>`
            htmlDetail += this.printList(detail.recommended)
            
            htmlDetail += `<p className="font-bold">Contraindicaciones</p>`
            htmlDetail += this.printList(detail.notRecommended)

            htmlDetail += `<p className="font-bold">Precauciones</p>`
            htmlDetail += this.printList(detail.precautions)

            htmlDetail += `<p className="font-bold">Oservaciones</p>`
            htmlDetail += this.printList(detail.observations)

            htmlDetail += `<p className="font-bold">Coadministración</p>`
            htmlDetail += this.printList(detail.coadministration)

            htmlDetail += `</div>`
    
        }
        return htmlDetail;
    }

    printList(item: any): string {
        let listHtml = `<ul class="list-disc px-6">`;
        
        for(let i = 0 ; i < item.length ; i++){
            listHtml += `<li>${item[i]}</li>`
        }
        listHtml += `</ul>`
        return listHtml;
    }
}

