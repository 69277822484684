import { NavLink } from 'react-router-dom'
import UCTS_BLANCO from '../../img/UCTS_BLANCO.png'
import VAX_CONNECT from '../../img/VAX_CONNECT_WHITE.png'
import { useState } from 'react';

export default function Main() {

    const appDate = process.env.REACT_APP_DATE;

    const [code, updateCode] = useState("123abc");
    const [email, updateEmail] = useState("");
    const [authorized, updateAuthorization] = useState(localStorage.getItem('authorized'));

    const checkCode = (e: any) => {

        e.preventDefault();

    fetch(process.env.REACT_APP_API_URL+'autorizar', {
        method: "POST",
        body: JSON.stringify({token: code, email: email}),
        headers: {
          'Content-Type': 'Application/json',
          'Token': 'Bearer CfXpCr4EgS0gmDQbO252ML4NkysrJTiJsh1AuDe0a3834909',
          'Access-Control-Allow-Origin': 'https://app.vax-connect.com'
        }
      })
        .then(response => response.json())
        .then(data => {
            if(true === data.access){
                localStorage.setItem('authorized', 'yes');
                updateAuthorization(localStorage.getItem('authorized'));
                console.log('code is valid');
            } else {
                console.log('code is invalid');
            }
        })
        .catch(e => alert('Código inválido'));
  
    }

    const onInputCodeChange = (e:any) => {
        updateCode(e.target.value);
    }
    const onInputEmailChange = (e:any) => {
        updateEmail(e.target.value);
    }

    if(authorized && authorized == 'yes'){
        console.log('is authorized')
    } else {
        console.log('not authorized');
    }

    return (
        <div className='flex flex-col items-stretch' style={{height: '100vh', maxWidth: '600px', margin: 'auto'}}>
            
            <div className='px-5 grow flex'>
                <img src={UCTS_BLANCO} alt="UCTS Logotipo" style={{alignSelf: 'center'}} />
            </div>

            <div className={authorized?'grow flex text-center justify-center only-pwa':'hidden'}>
                <NavLink className='rounded overflow-hidden shadow-md bg-business-dark-blue-text p-2 px-6 md:mx-2 text-center text-white text-2xl'
                    to={`algorithm`}
                    style={{alignSelf: 'center'}} >
                    Acceder
                </NavLink>
            </div>

            <div className={authorized?'hidden':'grow flex flex-col text-center justify-center w-11/12 m-auto only-pwa'}>
                <form onSubmit={(event) => checkCode(event)}>
                <input className='rounded overflow-hidden shadow-md p-2 mb-2 px-6 md:mx-2 text-center text-black text-2xl' value={email}
                onChange={onInputEmailChange} required type="text"/>
                <input className='rounded overflow-hidden shadow-md p-2 mb-2 px-6 md:mx-2 text-center text-black text-2xl' value={code}
                onChange={onInputCodeChange} type="text"/>
                <input type="submit" className='rounded overflow-hidden shadow-md bg-business-dark-blue-text p-2 px-6 md:mx-2 text-center text-white text-2xl' 
                    value="Validar" />
                </form>
            </div>

            <div className='grow flex'>
                <img src={VAX_CONNECT} style={{width: "50%", margin: "auto", alignSelf: 'center'}} alt="UCTS Logotipo" />
            </div>


            <p className="fixed flex flex-col content-center justify-center w-full" style={{"bottom": "0px", "left": "0px", "padding": "10px"}}>
                      
                <span className='text-center'>
                Versión Beta
                </span>
                <span className='text-center'>
                Datos del CDC actualizados a {appDate}
                </span>
            </p>

        </div>
    )
}