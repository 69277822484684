let vaccineAges = [
    {
        min: 0,
        max: 1,
        vaccines: [10,6,-1,6,10,10,10,10,10,10,10,10,10,10,2,10,10,10,10,10,-1]
    },
    {
        min: 1,
        max: 2,
        vaccines: [10,10,-1,6,10,10,10,10,10,10,10,10,10,10,2,10,10,10,10,10,-1]
    },
    {
        min: 2,
        max: 4,
        vaccines: [10,10,6,6,6,10,10,10,10,10,10,6,10,10,2,10,6,6,10,10,-1]
    },
    {
        min: 4,
        max: 6,
        vaccines: [10,10,6,5,6,10,10,10,10,10,10,6,10,10,2,10,6,6,10,10,-1]
    },
    {
        min: 6,
        max: 9,
        vaccines: [6,10,6,6,6,10,10,4,10,10,10,6,10,4,2,10,6,6,6,6,-1]
    },
    /* aca 5*/
    {
        min: 9,
        max: 12,
        vaccines: [6,10,5,6,5,10,10,4,10,10,10,5,10,4,3,10,6,5,6,6,4]
    },
    {
        min: 12,
        max: 15,
        vaccines: [6,10,6,6,5,10,10,6,6,10,10,6,10,6,3,10,6,10,6,6,4]
    },
    {
        min: 15,
        max: 18,
        vaccines: [6,10,6,6,6,10,10,6,6,10,10,6,10,6,3,10,6,10,6,6,4]
    },
    {
        min: 18,
        max: 19,
        vaccines: [6,10,5,6,6,10,10,5,5,10,10,5,10,6,3,10,6,10,6,6,6]
    },
    {
        min: 19,
        max: 24,
        vaccines: [6,10,5,5,5,10,10,5,5,10,10,5,10,6,3,10,5,10,6,6,4]
    },
    /* aca 10*/
    {
        min: 24,
        max: 48,
        vaccines: [6,10,5,5,5,10,10,5,5,10,10,5,4,5,3,10,5,10,6,6,4]
    },
    {
        min: 48,
        max: 84,
        vaccines: [6,10,4,5,6,10,10,6,6,10,10,4,4,5,3,10,6,10,6,6,4]
    },
    {
        min: 84,
        max: 108,
        vaccines: [6,10,4,5,10,5,5,5,5,10,10,4,4,5,3,10,5,10,6,6,4]
    },
    {
        min: 108,
        max: 120,
        vaccines: [6,10,4,5,10,5,5,5,5,10,3,4,4,5,3,4,5,10,6,6,4]
    },
    {
        min: 120,
        max: 132,
        vaccines: [6,10,4,5,10,5,5,5,5,10,3,4,4,5,3,4,5,10,6,6,4]
    },

    /* aca 15*/
    {
        min: 132,
        max: 156,
        vaccines: [6,10,4,5,10,5,6,5,5,10,6,4,4,5,6,4,5,10,6,6,4]
    },
    {
        min: 156,
        max: 192,
        vaccines: [6,10,4,5,10,5,4,4,5,10,5,4,4,5,5,4,5,10,6,6,4]
    },
    {
        min: 192,
        max: 204,
        vaccines: [6,10,4,5,10,5,4,4,5,10,5,4,4,5,6,3,5,10,6,6,4]
    },
    {
        min: 204,
        max: 228,
        vaccines: [6,10,4,5,10,5,4,4,5,10,5,4,4,5,5,3,5,10,6,6,4]
    },
    {
        min: 228,
        max: 324,
        vaccines: [6,4,4,5,10,5,4,5,5,4,5,4,4,4,4,3,4,10,6,6,4]
    },

    /* aca 20*/
    {
        min: 324,
        max: 540,
        vaccines: [6,4,4,5,10,4,4,5,4,4,3,4,4,4,4,4,4,10,6,6,4]
    },

    {
        min: 540,
        max: 600,
        vaccines: [6,4,4,5,10,4,4,5,4,4,3,4,4,4,4,4,4,10,6,6,4]
    },

    {
        min: 600,
        max: 720,
        vaccines: [6,4,4,5,10,4,4,5,4,6,-1,4,4,4,4,4,4,10,6,6,4]
    },
    {
        min: 720,
        max: 780,
        vaccines: [6,4,4,3,10,4,4,5,4,6,-1,4,6,4,4,4,4,10,6,6,3]
    },
    {
        min: 780,
        max: 10000,
        vaccines: [6,4,4,4,10,4,4,3,4,6,-1,6,6,4,4,4,4,10,6,6,3]
    },
];

export default vaccineAges;