import { Outlet, Link, NavLink } from "react-router-dom"

export default function Navbar(props: {title: string}) {

  if((props.title.length === 0)) return null;

    return (
      <nav className="z-10 w-full fixed bottom-0 bg-gray-100 grid grid-cols-2 justify-between appnav-osx" style={{'left':0+'px'}}>
        <NavLink 
          className="p-3 text-black shadow-md bg-white text-center" 
          to={`algorithm`}>
          Algoritmo
        </NavLink>
        <NavLink 
          className="p-3 text-black shadow-md bg-white text-center flex-col" 
          to={`library`}>
          Lecturas y Recursos
        </NavLink>
      </nav>
    )
}