import AgeVaccination from "./AgeVaccination";
import { Paciente } from "./types/Paciente";

export default class Algorithm {
    constructor(){
    }

    calculate(paciente: Paciente){
        return new AgeVaccination(paciente).handle([]);
    }

}