import { Offline, Online } from "react-detect-offline";

import OFFLINE_RED from '../../img/offline-circle.png';

export default function Connected()
{
    return (
    	<div className="px-0 py-3">
      		<Offline>
        		<p className="px-3" style={{marginTop: '2px'}}>
                    <img src={OFFLINE_RED} alt="Sin internet" className="w-6" />
                </p>
      		</Offline>
    	</div>
  	);
}