import Connected from "./connected";

export default function Titlebar(props: {title: string, showMenu: any}) {

  if((props.title.length === 0)) return null;

    return (
      <span className="z-10 fixed w-full top-0-important flex justify-between bg-business-dark-blue-text shadow-md">
        
        <h1 className="p-3 pl-3 ml-1 text-xl text-white">{props.title}</h1>

        <span className="flex">
        <Connected/>
        
        <button className="text-white mr-2 p-3" onClick={event => props.showMenu()}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="white" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
          </svg>
        </button>
        </span>
      </span>
    )
}