import { useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

export default function VaccinationFeedbackModal(props: { changeFeedbackModal: any; }){
        
  const changeFeedbackModal = props.changeFeedbackModal;


  const [feedbackText, updateFeedbackText] = useState("");
  const [email, updateEmailText] = useState("");

  if(localStorage.getItem('email') != undefined && localStorage.getItem('email') != null ){
    updateEmailText(localStorage.getItem('email')!); 
  }

  const handleInputChange = (event:any) => {
    updateFeedbackText(event.target.value);
  };

  const handleEmailChange = (event:any) => {
    updateEmailText(event.target.value);
  };


  
  const sendFeedback = (e: any) => {
    e.preventDefault();
    
    fetch(process.env.REACT_APP_API_URL+'report', {
      method: "POST",
      body: JSON.stringify({text: feedbackText, email: email}),
      headers: {
        'Content-Type': 'Application/json',
        'Token': 'Bearer CfXpCr4EgS0gmDQbO252ML4NkysrJTiJsh1AuDe0a3834909',
        'Access-Control-Allow-Origin': 'https://app.vax-connect.com'
      }
    })
      .then(response => response.json())
      .then(data => {
          if(data.status === 200){
            alert("Gracias por su colaboración.");
            changeFeedbackModal(false);
          } else {
            alert("Ha ocurrido un error. Vuelva a intentar");
          }
      })
      .catch(e => alert('Ha ocurrido un error'));

  }

    return (
        <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 sm:ml-4 sm:mt-0 sm:text-left">
                    <div className="mb-5 flex">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="black" className="mt-2 ml-3 w-12 h-12">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 12.75c1.148 0 2.278.08 3.383.237 1.037.146 1.866.966 1.866 2.013 0 3.728-2.35 6.75-5.25 6.75S6.75 18.728 6.75 15c0-1.046.83-1.867 1.866-2.013A24.204 24.204 0 0 1 12 12.75Zm0 0c2.883 0 5.647.508 8.207 1.44a23.91 23.91 0 0 1-1.152 6.06M12 12.75c-2.883 0-5.647.508-8.208 1.44.125 2.104.52 4.136 1.153 6.06M12 12.75a2.25 2.25 0 0 0 2.248-2.354M12 12.75a2.25 2.25 0 0 1-2.248-2.354M12 8.25c.995 0 1.971-.08 2.922-.236.403-.066.74-.358.795-.762a3.778 3.778 0 0 0-.399-2.25M12 8.25c-.995 0-1.97-.08-2.922-.236-.402-.066-.74-.358-.795-.762a3.734 3.734 0 0 1 .4-2.253M12 8.25a2.25 2.25 0 0 0-2.248 2.146M12 8.25a2.25 2.25 0 0 1 2.248 2.146M8.683 5a6.032 6.032 0 0 1-1.155-1.002c.07-.63.27-1.222.574-1.747m.581 2.749A3.75 3.75 0 0 1 15.318 5m0 0c.427-.283.815-.62 1.155-.999a4.471 4.471 0 0 0-.575-1.752M4.921 6a24.048 24.048 0 0 0-.392 3.314c1.668.546 3.416.914 5.223 1.082M19.08 6c.205 1.08.337 2.187.392 3.314a23.882 23.882 0 0 1-5.223 1.082" />
                      </svg>
                      <span className="ml-5">
                        <h2 className="text-lg strong">Reporte de errores</h2> 
                        <p>Si ha encontrado un error, puede escribir un detalle del mismo en el siguiente formulario.</p>
                      </span>
                    </div>
                    <div className="mt-2">
                      <form onSubmit={(e) => sendFeedback(e)}>
                        <label htmlFor="error_description">Breve descripción del error</label><br />
                        <div className='textwrapper'>
                          <textarea
                            value={feedbackText}
                            onChange={handleInputChange}
                            required={true}
                            placeholder="Enter text here..."
                            rows={15}
                          />
                        </div><br />
                        <input type="submit" value="Enviar" className='px-5 py-1 mx-auto business-blue text-white rounded flex'/>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                    onClick={() => changeFeedbackModal(false)}  
                    type="button" 
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cerrar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}