import ConditionVaccination from "./ConditionVaccination";
import { Paciente } from "./types/Paciente";

export default class GenderVaccination{

    readonly vaccinePlan : Array<number> = [5,10,10,5,10,5,10,10,10,10,5,10,10,10,10,10,10,10,10,10,10]

    paciente: Paciente;

    constructor(paciente: Paciente){
        this.paciente = paciente;
    }

    handle(vaccines: Array<Array<number>>) : Array<number>
    {
        if(this.paciente.gender === 'mujer'){
            vaccines.push(this.vaccinePlan);
        };
        
        return new ConditionVaccination(this.paciente).handle(vaccines);
    }
}