import { useEffect, useState } from "react";
import Media from "../components/media";

export default function Videos() {

  const [media, setMedia] = useState([{id:0,description: '', type:null}]);

  useEffect(()=> {
    fetch(process.env.REACT_APP_API_URL+'media', {
      headers: {
        'Content-Type': 'Application/json',
        'Token': 'Bearer CfXpCr4EgS0gmDQbO252ML4NkysrJTiJsh1AuDe0a3834909',
        'Access-Control-Allow-Origin': 'https://app.vax-connect.com'
      }
    })
      .then(response => response.json())
      .then(data => setMedia(data));
  }, [process.env.API_URL+'media'])
  

  return (
    <div className='flex-col px-3 md:flex-row mb-10 pb-10 app-body-margin-top'>
        {media.map( medium => (
          <div key={medium.id} className="rounded overflow-hidden  shadow-md bg-gray-100 mt-4 md:mx-2">
            <div className="px-6 py-4">
              <div className="font-bold text-xl mb-2 text-black">{medium.description}</div>
              
              <p className="text-gray-700 text-base">
                <Media medium={medium} />
              </p>
            </div>
          </div>
        ))}
    </div>
  )
}