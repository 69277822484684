import _ from "lodash";
import paiAges from "../data/vaccineAgesPai";
import { PaiVaccine } from "./AgeVaccine";
import { Paciente } from "./types/Paciente";

export default class VaccineInstitution {

    readonly paiAges: Array<PaiVaccine> = paiAges;

    vaccinesByOrganism(paciente: Paciente) : any
    {
        let foundPaiAges: any;

        let calculatedAge: number;

        if(paciente.ageUnits === "anios"){
            calculatedAge = paciente.age * 12;
        } else {
            calculatedAge = paciente.age;
        }

        this.paiAges.map(function(current){
            if(((current.min <= calculatedAge) && (calculatedAge < current.max))){
                foundPaiAges = current;
            }
        })

        let organismByAge = foundPaiAges.organism;
        let paiCoveredVaccines: Array<Array<number>> = [];
        
        paiCoveredVaccines.push(organismByAge);

        let finalList : any =  _.zip(...paiCoveredVaccines).map(list => _.max(list));

        if(paciente.gender == "hombre"){
            finalList[5] = 0; // TD no cubierto para hombres
            finalList[6] = 0; // TDAP no cubierto para hombres
            finalList[10] = 0; // TDAP no cubierto para hombres
        }

        if(paciente.gender == "mujer"){
            if(calculatedAge >= 120 && calculatedAge < 600){
                finalList[5] = 1; // TD solo para mujeres en edad fertil
            } else {
                finalList[5] = 0; 
            }

            if(paciente.conditions[0] != 100){ // embarazo
                finalList[6] = 1; // TDAP cubierto para embarazadas
                finalList[18] = 1; // Influenza estacional cubierto para embarazadas
            } else {
                finalList[6] = 0; // TDAP no cubierto si no embarazada
            }
            
        }

        if(paciente.conditions[10] != 100){ // Zonas endemicas
            finalList[13] = 1; // Hepatitis A
            finalList[20] = 1; // Fiebre amarilla
        }

        if(paciente.conditions[11] != 100){ // Personal de salud
            finalList[3] = 1; // Hepatitis B
        }

        return finalList;
    }

}